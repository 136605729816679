<template lang="pug">
div.input__wrapper
  select.wagtail-form__select(
    :value="inputValue"
    v-model="userValue"
    @blur="emitValue"
    :class='{invalid: !valid}')
    option(value="" disabled) {{ value.help_text }}
    option.wagtail-form__option(v-for="name in value.choices" :value="name") {{ capitalizeFirstLetter(name) }}
  small.error {{ !valid ? errorMessage : '' }}
</template>

<script>
export default {
  props: {
    inputValue: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    inputError: {
      type: Boolean,
      default: false,
    },
    isFormValidate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userValue: '',
    valid: true,
    errors: {
      required: false,
    },
    errorMessage: '',
    errorMessageText: {
      required: 'Выберите услугу',
    },
  }),
  watch: {
    isFormValidate() {
      this.validate()
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0) + string.slice(1).toLowerCase();
    },
    validate() {
      this.errors['required'] = !this.userValue
      if (this.errors.required) {
        this.errorMessage = this.errorMessageText.required
      }
      this.valid = !this.errors.required
    },
    emitValue() {
      this.validate()
      this.$emit('onValueChange', { type: this.value.slug, value: this.userValue, valid: this.valid })
    },
  },
}
</script>

<style lang="scss" scoped>
.wagtail-form {
  &__select {
    width: 100%;
    border: none;
    color: $gray;
    font-size: 13px;
    padding: 7px;
    resize: none;
    // margin-bottom: 14px;
    border-bottom: 1px solid $gray;
    background-color: transparent;
  }
}

.invalid {
  border-bottom: 1px solid red;
}
</style>
