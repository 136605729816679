export const usePageHeadMeta = (title, description, ogs) => {
  // console.log('og', ogs)
  // console.log('title', title)
  // console.log('description', description)
  const metaDescription = reactive({
    name: 'description',
    content: description,
  })

  const metaTitle = reactive({
    name: 'title',
    content: title,
  })

  let ogArr = reactive([])
  for (let og in ogs) {
    ogArr.push({
      property: `og:${og}`,
      content: ogs[og],
    })
  }

  const meta = [
    metaTitle,
    metaDescription,
    ...ogArr,
  ]

  return useHead({
    title,
    meta,
  })
}
