<template lang="pug">
.base-checkbox
  input(
    type="checkbox" 
    class="base-checkbox__input" 
    :id="name" 
    :name="name"     
    :required="required"
    :value="value"
    v-model="model")
  label.base-checkbox__label(:for="name") {{ name }}
</template>

<script>
export default {
  props: ['name', 'value', 'modelValue', 'required'],
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.base-checkbox__input+.base-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  color: $gray;
  font-size: 13px;
}

.base-checkbox__input+.base-checkbox__label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  position: relative;
  top: 3px;
}

.base-checkbox__input:checked+.base-checkbox__label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.base-checkbox__input:not(:disabled):active+.base-checkbox__label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
</style>