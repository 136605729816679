<template lang="pug">
div.input__wrapper
  input.wagtail-form__singleline(
    :ref="value.slug"
    :type="computedType"
    :placeholder="value.help_text"
    v-model="userValue"
    @blur="emitValue"
    :class='{invalid: !valid}')
  small.error {{ !valid ? errorMessage : '' }}
</template>

<script>
export default {
  props: {
    inputValue: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    inputError: {
      type: Boolean,
      default: false,
    },
    isFormValidate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: [
      {
        type: 'phone',
        value: /^[\+]?[0-9]{0,3}[-\s]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{2,4}[-\s]?[0-9]{2,4}[-\s]?[0-9]{2,4}$/,
      },
      {
        type: 'email',
        value: /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/,
      },
    ],
    errors: {
      required: false,
      pattern: false,
    },
    valid: true,
    userValue: '',
    errorMessage: '',
    errorMessageText: {
      required: 'Поле не должно быть пустым',
      pattern: {
        phone: 'Неверный формат или превышены 20 символов',
        email: 'Неверный формат',
      },
    },
  }),
  computed: {
    computedType() {
      switch (this.type) {
        case 'singleline':
          return 'text'
        case 'number':
          return 'tel'
        default:
          return this.type
      }
    },
  },
  watch: {
    isFormValidate() {
      this.validate()
    }
  },
  methods: {
    validate() {
      this.errors['required'] = !this.userValue

      this.rules.forEach((rule) => {
        if (this.value.slug === rule.type) {
          this.errors['pattern'] = !rule.value.test(this.userValue)
        }
      })

      if (this.errors.required) {
        this.errorMessage = this.errorMessageText.required
      } else if (this.errors.pattern) {
        this.errorMessage = this.errorMessageText.pattern[this.value.slug]
      }

      this.valid = !this.errors['required'] && !this.errors['pattern']
    },
    emitValue() {
      this.validate()
      this.$emit('onValueChange', { type: this.value.slug, value: this.userValue, valid: this.valid })
    },
  },
}
</script>

<style lang="scss" scoped>
.wagtail-form__singleline {
  width: 100%;
  border: none;
  padding: 7px;
  color: $gray;
  font-size: 13px;
  border-bottom: 1px solid $gray;
  background-color: transparent;

  &::placeholder {
    color: $gray;
  }
}

.invalid {
  border-bottom: 1px solid red;
}
</style>
