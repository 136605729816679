import { useReCaptcha } from 'vue-recaptcha-v3'

export const useVueRecaptcha = () => {
  const recaptchaToken = ref(null)
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

  const recaptcha = async () => {
    // (optional) Wait until recaptcha has been loaded.
    await recaptchaLoaded()
    // Execute reCAPTCHA with action "login".
    recaptchaToken.value = await executeRecaptcha("login").catch((err) => console.log(err))
    // Do stuff with the received token.
    // console.log({ recaptchaToken });
    return recaptchaToken
  }

  return {
    recaptcha,
    recaptchaToken,
  }
}
