<template lang="pug">
div
  form.wagtail-form(
    novalidate
    @submit.prevent="validate"
    v-if="isReady && !isFormSubmitted"
    @submitted="isFormSubmitted=true")
      component(
        v-for="field in formValue.fields_data"
        :key="field.id"
        :value="field.value"
        :type="field.type"
        :is="getType(field.type)"
        :inputValue="getInputValue(requestFields, field.value.slug, field.type, field.value.default_value)"
        @onValueChange="setRequestField"
        :inputError="errors[field.value.slug]"
        :isFormValidate="errors.validate"
        )
      BaseBtn.btn-footer {{ formValue.submit_button_text }}
  template(v-if="isFormSubmitted").wagtail-form__submitted
    h1.wagtail-form__submitted-title
      | Спасибо
      br
      | за обращение!
    p.wagtail-form__submitted-subtitle
      | Мы свяжемся с вами
      br
      | в ближайшее время
</template>

<script>
import checkboxes from './fields/Checkboxes'
import multiline from './fields/Multiline'
import singleline from './fields/Singleline'
import recaptcha from './fields/Recaptcha'
import dropdown from './fields/Dropdown'
import { streamFormUrl } from '~/helpers/urls'

export default {
  setup() {
    const { recaptcha: recaptchaFn } = useVueRecaptcha()

    return { recaptchaFn }
  },
  components: {
    checkboxes,
    multiline,
    singleline,
    recaptcha,
    dropdown,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vacancy: {
      type: String
    }
  },
  data() {
    return {
      requestFields: {},
      formValue: {},
      isReady: false,

      retryCount: 0,
      retryLimit: 15,

      isFormSubmitted: false,

      recaptchaToken: '',
      errors: {},
    }
  },
  async mounted() {
    await this.getFormData()
  },
  methods: {
    validate() {
      const err = { ...this.errors }
      err['validate'] = true
      const values = Object.values(this.errors)

      if (values.length && values.every((el) => el)) {
        this.postFormData()
      } else {
        this.errors = err
      }
    },
    async getFormData() {
      if (this.value.form) {
        const res = await useApiFetch(`${streamFormUrl}${this.value.form}/`)
        //   console.log('res', res)
        if (this.vacancy) {
          res.fields_data[3].value.default_value = this.vacancy
        }
        this.formValue = res
        this.isReady = true
        this.retryCount = 0
      } else if (this.retryCount <= this.retryLimit) {
        this.retryCount += 1
        setTimeout(this.getFormData, 500)
      }

      if (this.formValue.fields_data) {
        this.getErrorsStructure(this.formValue.fields_data)
      }
    },
    getInputValue(obj, key, type, value) {
      if (value) {
        obj[key] = value
      }
      if (!obj[key]) obj[key] = type === 'checkboxes' ? [] : '';

      return obj[key]
    },
    getErrorsStructure(fields) {
      fields.forEach((field) => {
        if (field.value.slug !== 'recaptcha' && field.type !== 'checkboxes') {
          this.errors[field.value.slug] = false
        }
      })
    },
    setRequestField({ type, value, valid = true }) {
      this.requestFields[type] = value
      this.errors[type] = valid
    },
    getType(type) {
      switch (type) {
        case 'number':
          return 'singleline';
        case 'email':
          return 'singleline';
        case 'singleline':
          return 'singleline';
        case 'dropdown':
          return 'dropdown';
        default:
          return type;
      }
    },
    async postFormData() {
      const token = await this.recaptchaFn()

      try {
        const body = {
          ...this.requestFields,
          form_id: this.value.form,
          form_reference: this.value.form_reference,
          recaptcha: token.value
        }

        const res = await useApiFetch(
          `${streamFormUrl}${this.value.form}/submit/`,
          {
            method: 'POST',
            body,
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => {
            this.isFormSubmitted = true

            //fbq('track', 'Lead'); // facebook
            //this.sendCustomFacebookEvent(this.$route.path);
          })
        this.$emit('submitted')
      } catch (error) {
        console.log(error)
      }
    },
    sendCustomFacebookEvent(path) {
      const PATHS = ['portfolio', 'method', 'technology', 'flutter', 'about', 'blog']

      if (path.includes('contacts')) {
        fbq('trackCustom', 'contactPageForm');
      } else if (path.includes('project')) {
        fbq('trackCustom', 'projectPageForm');
      } else if (path.includes('vacancy')) {
        fbq('trackCustom', 'vacancyPageForm');
      } else if (path === '/' || PATHS.some(v => path.includes(v))) {
        fbq('trackCustom', 'discussProjectForm');
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.wagtail-form {
  width: 335px;

  @media (--small-viewport-df) {
    min-width: 280px;
    width: auto;
    max-width: 335px;
  }

  &__submitted-title {
    color: $primary;

    @media (--small-viewport-df) {
      text-align: center;
    }
  }

  &__submitted-subtitle {
    color: $primary;

    @media (--small-viewport-df) {
      text-align: center;
    }
  }
}

.btn-footer {
  width: 100%;

  @media (--extra-samll-viewport-mf) {
    width: var(--btn-width);
  }
}

.home-page__content .contact__form>.wagtail-form .btn-footer {
  @media (--small-range-viewport) and (--low-height-screen) {
    margin-top: 20px;
  }
}


// .contact__form>.wagtail-form {
//   max-width: 335px;

//   @media (--small-viewport-df) {
//     width: auto;
//     max-width: 335px;
//   }
// }

// :not(.contact__form)>.wagtail-form {
//   width: 335px;

//   @media (--small-viewport-df) {
//     width: auto;
//     max-width: 335px;
//   }
// }
</style>

<style lang="scss">
.input__wrapper {
  display: flex;
  flex-direction: column;
}

.error {
  width: 100%;
  height: calc(1rem * 1.2);
  color: red;
}
</style>
