<template lang="pug">
div.input__wrapper.input__wrapper--text
  textarea.wagtail-form__multiline(
    :placeholder="value.help_text"
    v-model="userValue"
    @blur="emitValue"
    :class='{invalid: !valid}')
  small.error {{ !valid ? errorMessage : '' }}
</template>

<script>
export default {
  props: {
    inputValue: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    inputError: {
      type: Boolean,
      default: false,
    },
    isFormValidate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userValue: null,
    valid: true,
    errors: {
      required: false,
    },
    errorMessage: '',
    errorMessageText: {
      required: 'Поле не должно быть пустым',
    },
  }),
  watch: {
    isFormValidate() {
      this.validate()
    }
  },
  methods: {
    validate() {
      this.errors['required'] = !this.userValue
      if (this.errors.required) {
        this.errorMessage = this.errorMessageText.required
      }
      this.valid = !this.errors.required
    },
    emitValue() {
      this.validate()
      this.$emit('onValueChange', { type: this.value.slug, value: this.userValue, valid: this.valid })
    },
  },
}
</script>

<style lang="scss" scoped>
.wagtail-form__multiline {
  width: 100%;
  min-height: 115px;
  border: none;
  color: $gray;
  font-size: 13px;
  padding: 7px;
  resize: none;
  border-bottom: 1px solid $gray;
  background-color: transparent;

  &::placeholder {
    color: $gray;
  }

  @include l-desktop {
    min-height: 127px;
  }
}

.invalid {
  border-bottom: 1px solid red;
}
</style>
