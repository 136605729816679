<template lang="pug">
.wagtail-form__recaptcha(v-show="false")
</template>

<script>
export default {
    props: {
        inputValue: {
            type: String,
            required: true
        },
        
        value: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        }
    }
}
</script>

<style lang="scss" scoped>
</style>