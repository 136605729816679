<template lang="pug">
.wagtail-form__checkboxes
  p.wagtail-form__checkboxes-label {{ value.label }}
  .wagtail-form__checkboxes-list
    BaseCheckbox(
      v-for="name in value.choices"
      :name="name"
      :key="name"
      :value="name"
      v-model="inputValue")
  small.error {{ !valid ? errorMessage : '' }}
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    inputError: {
      type: Boolean,
      default: false,
    },
    isFormValidate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: [],
      valid: true,
      errorMessage: 'Выберите уровень',
    }
  },
  watch: {
    inputValue(value) {
      this.validate()
      this.onChange(value)
    },
    isFormValidate() {
      this.validate()
    }
  },
  methods: {
    onChange(newValue) {
      this.$emit('onValueChange', {
        type: this.value.slug,
        value: newValue,
        valid: this.valid,
      })
    },
    validate() {
      this.valid = this.inputValue.length ? true : false
    }
  },
}
</script>

<style lang="scss" scoped>
.wagtail-form {
  &__checkboxes {
    width: 100%;
    margin-bottom: 30px;
  }

  &__checkboxes-label {
    font-size: 13px;
    color: $dark;
    margin-bottom: 10px;
  }

  &__checkboxes-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.error {
  display: block;
}
</style>
